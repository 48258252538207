// html
import "./html/index.html";
import "./html/home.html";
import "./html/landing.html";
import "./html/program.html";
import "./html/tertiary.html";
import "./html/tertiary-split.html";
import "./html/tertiary2.html";
import "./html/subsite.html";
import "./html/subsite-tpc.html";

// js
import "./js/script.js";

// // scss
import "./scss/style.scss";

// imgs
